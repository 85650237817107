.vs-lg {
    max-width: 750px;
    margin: 0px auto;
}

.vs-li {
    padding: 25px;
}

.vs-li p {
    padding: 10px;
}